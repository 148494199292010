import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { keyframes, css } from 'styled-components'
import { rgba } from 'polished'

import { devices } from '../../../theme'
import { TABLE_SORT_KEY } from '../../../utils/constants'
import {
  setPriceDecimal,
  setLargeNumberFormat,
  tableReducer
} from '../../../utils'
import { Row, Column } from './Grid'

const TableContainer = styled.div`
  width: 100%;
  border: 1px solid ${ props => props.theme.color.grey[2] };
  border-radius: 4px;
  box-shadow: 0 4px 20px ${ rgba('#000', 0.05) };
`

const TableCell = styled(Column)`
  padding: 1em;
  color: ${ props => props.theme.color.grey[5] };

  @media (${ devices.tabletWide }) {
    max-width: 100%;
    flex-basis: 100%;
    align-items: center;
  }
`

const TableRow = styled(Row)`
  margin: 0;

  &:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }

  :hover {
    background-color: ${ props => rgba(props.theme.color.grey[0], 0.5) };

    a span {
      color: ${ props => props.theme.color.primaryColor };
    }
  }

  @media (${ devices.tabletWide }) {
    margin: initial;
    padding: initial;

    ${ Column } {
      flex-basis: 50%;
      border: 1px solid ${ props => props.theme.color.grey[0] };
      border-left: none;
      border-top: none;

      &:nth-child(-n + 2) {
        background-color: ${ props => rgba(props.theme.color.grey[0], 0.3) };
        border-bottom: none;
        border-right: none;
      }

      &:not(:nth-child(-n + 2)) {
        padding: 1.2em;
      }

      &:nth-child(even) {
        border-right: none;
      }

      &:nth-last-child(-n + 2) {
        border-bottom: none;
      }
    }

    :hover {
      background-color: transparent;
    }
  }
`

const TableHeader = styled(TableRow)`
  border-bottom: 1px solid ${ props => props.theme.color.grey[0] };
  margin: 0;

  :hover {
    background-color: transparent;
  }

  ${ TableCell } {
    padding: 0;
  }

  &:first-child {
    padding-left: 2.7em;
  }

  @media (${ devices.tabletWide }) {
    display: none;
  }
`

const TableHeaderCell = styled.div`
  color: ${ props => props.active.key && props.theme.color.primaryColor };
  font-size: 1.4rem;
  font-weight: 700;
  cursor: pointer;
  padding: 1.5em;

  :hover {
    color: ${ props => props.theme.color.primaryColor };
  }

  ${ props =>
    props.active.key &&
    css`
      &::after {
        content: ${ props => (props.active.order === 'DESC' ? '\'▴\'' : '\'▾\'') };
        display: inline-block;
      }
    ` }
`

const fadeIn = keyframes`
  100% {
    opacity: 1;
  }
`

const Order = styled(TableCell)`
  flex-basis: 6% !important;
  max-width: 6% !important;

  @media (${ devices.tabletWide }) {
    span {
      background-color: ${ ({ colors }) =>
    `rgba(${ colors.r }, ${ colors.g }, ${ colors.b }, 0.2)` };
      color: ${ ({ colors }) => `rgb(${ colors.r }, ${ colors.g }, ${ colors.b })` };
      line-height: 1;
      padding: 1.3px 0.3em;
      border-radius: 20px;
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: -20px;
      font-size: 1.2rem;
    }
  }
`

const Name = styled(Column)`
  a {
    display: flex;
    align-items: center;

    span {
      color: ${ props => props.theme.color.grey[6] };
      font-size: 1.8rem;
      padding: 0.5em;
    }
  }

  @media (${ devices.tabletWide }) {
    align-self: stretch;
    justify-content: center;
  }
`

const Icon = styled.div`
  background-color: rgb(
    ${ props => props.rgba.r },
    ${ props => props.rgba.g },
    ${ props => props.rgba.b }
  );
  width: 32px;
  height: 32px;
  border-radius: 20px;
  position: relative;

  &::before {
    content: "";
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${ props => props.src });
    opacity: 0;
    animation: ${ fadeIn } 0.5s ease-in forwards;
  }
`

const Change = styled.span`
  background-color: ${ props =>
    props.change ? rgba('#29c029', 0.05) : rgba('#d72b2b', 0.05) };
  color: ${ props => (props.change ? '#29c029' : '#d72b2b') };
  padding: 0 0.5em;
  border-radius: 20px;
`

const AssetPrice = styled.span`
  font-size: 1.8rem;
  color: ${ props => props.theme.color.grey[6] };
`

const DollarSign = styled.span`
  color: ${ props => props.textColor };
  font-size: 1.4rem;
  background-color: ${ props => rgba(props.textColor, 0.1) };
  padding: 0.1em 0.4em;
  border-radius: 20px;
  line-height: 0;
  margin-right: 0.3em;
`

const MobileHeader = styled.span`
  display: none;
  color: ${ props => rgba(props.theme.color.grey[4], 0.8) };
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;

  @media (${ devices.tabletWide }) {
    display: inline;
  }
`

const Table = ({ header, rowData }) => {
  const initialState = {
    data: rowData,
    sortColumn: 'market_cap',
    sortDirection: 'DESC'
  }

  const [state, dispatch] = useReducer(tableReducer, initialState)
  
  useEffect(() => dispatch({ type: 'SORT_COLUMN', column: 'market_cap' }), [])
  return (
    <TableContainer>
      <TableHeader>
        {Object.keys(header).map((el, idx) => (
          <TableCell
            onClick={() =>
              dispatch({ type: 'SORT_COLUMN', column: TABLE_SORT_KEY[el] })
            }
            key={idx}
          >
            <TableHeaderCell
              active={{
                key: state.sortColumn === TABLE_SORT_KEY[el],
                order: state.sortDirection
              }}
            >
              {header[el]}
            </TableHeaderCell>
          </TableCell>
        ))}
      </TableHeader>
      {state.data &&
        state.data.map(({ node }, idx) => (
          <TableRow
            key={node.id}
            align="center"
          >
            <Order
              align="center"
              colors={node.coin.color}
            >
              <span>{idx + 1}</span>
            </Order>
            <Name>
              <Link to={`/price/${ node.coin.symbol_name }`}>
                <Icon
                  src={node.coin.icon}
                  rgba={node.coin.color}
                />
                <span>{node.coin.name}</span>
              </Link>
            </Name>
            <TableCell>
              <MobileHeader>Price (24h)</MobileHeader>
              <AssetPrice>
                <DollarSign textColor="green">$</DollarSign>
                {setPriceDecimal(node.coin.price_usd)}
              </AssetPrice>
            </TableCell>
            <TableCell align="flex-start">
              <MobileHeader>Change</MobileHeader>
              <Change change={node.coin.change.includes('+')}>
                {node.coin.change}
              </Change>
            </TableCell>
            <TableCell>
              <MobileHeader>Market Cap</MobileHeader>
              <span>
                <DollarSign textColor="#ff960f">$</DollarSign>
                {setLargeNumberFormat(node.coin.market_cap)}
              </span>
            </TableCell>
            <TableCell>
              <MobileHeader>Volume (24h)</MobileHeader>
              <span>
                <DollarSign textColor="#258cff">$</DollarSign>
                {setLargeNumberFormat(node.coin.volume_usd)}
              </span>
            </TableCell>
          </TableRow>
        ))}
    </TableContainer>
  )
}

export { Table, TableRow, TableHeader, TableCell }

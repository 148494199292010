import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Seo from '../components/seo'
import { Container, Row, Column } from '../components/ui/layout/Grid'
import { Table } from '../components/ui/layout/Table'
import { TABLE_HEADER_NAME } from '../utils/constants'

const Legal = styled.div`
  border: 1px solid ${ props => props.theme.color.grey[1] };
  padding: 2em;

  h5 {
    margin-top: 0;
  }

  p {
    font-size: 1.5rem;
  }
`

const Price = () => {
  const { allAssetList: assets } = useStaticQuery(assetQuery)
  return (
    <>
      <Seo title="Cryptocurrency Market Data" />
      <Container>
        <h1>Investabit Cryptocurrency Market Data</h1>
        <Table
          header={TABLE_HEADER_NAME}
          rowData={assets.edges}
        />
      </Container>
      <Container>
        <Row>
          <Column>
            <Legal>
              <h5>Legal Disclaimer</h5>
              <p>
                Investabit’s Indexes, Price Data and this website are provided on an "as is" basis and Investabit Capital Inc makes
                no representations or warranties as to the quality, accuracy or suitability of Investabit Index or this
                website. You acknowledge and agree that Investabit Captial, Inc. is not acting as an investment advisor
                or portfolio management by providing access to the Investabit Indexes and shall bear no responsibility
                or liability for any loss or damage, howsoever caused, including due to amendment of this website or the
                index methodology or calculations, resulting from or in connection with your use of Investabit Indexes
                or this website for any purpose.
              </p>
            </Legal>
          </Column>
        </Row>
      </Container>
    </>
  )
}

export const assetQuery = graphql`
  query allAssets {
    allAssetList(sort: { fields: [coin___market_cap], order: DESC }) {
      edges {
        node {
          id
          coin {
            added
            icon
            color {
              b
              g
              r
            }
            market_cap
            market_data_time
            name
            price_btc
            price_usd
            slug
            symbol_name
            volume_usd
            change
          }
        }
      }
    }
  }
`

export default Price
